/*     General overwrite     */
script ~ iframe{display:none !important;}
body,
.wrapper{
    min-height: 100vh;
    position: relative;
}
a{
  color: $theme-color;

  &:hover, &:focus{
    color: $theme-color;
     text-decoration: none;
  }
}
body{
    min-width:280px;
    background:$white-bg;
}
i.fa.fa-plus {
    color: green;
}
a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner{
    outline:0;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
    outline : 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert{
    @include transition($general-transition-time, $transition-linear);
}


.animation-transition-fast,
.sidebar .nav .collapse a > span,
.sidebar .sidebar-wrapper > .nav p,
.sidebar .user .info > a > span,
.sidebar .logo a.logo-normal,
.sidebar .user .info .collapse .nav a > span {
    @include transition($fast-transition-time, $transition-linear);
}

.table > tbody > tr .td-actions .btn{
    @include transition($fast-transition-time, $transition-ease-in);
    border:1px solid #ffff !important;
    margin:0 5px 0 0;
}

.btn{
    @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa,.fas,.far,.fal,.fad,.fab{
    width: 18px;
    text-align: center;
}
.margin-top{
    margin-top: 50px;
}

.wrapper{
    position: relative;
    top: 0;
    height: 100vh;
    overflow-x: hidden;
}

.card .map{
    height: 400px;

    .map-big{
        height: 350px;
    }
}


// documentation

.page-header{
    .page-header-image{
        background-position: center center;
        background-size: cover;
        height: 450px;
        overflow: hidden;
        width: 100%;
        z-index: 1;
    }
    .title-container{
        color: #fff;
        position: relative;
        top: 200px;
        z-index: 3;
    }
    .filter:after{
        background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #9368e9 0%, #943bea 100%) repeat scroll 0 0 / 150% 150%;
        content: "";
        display: block;
        height: 450px;
        left: 0;
        opacity: 0.77;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
}

// accordion

.form-control,
.input-group-addon,
.tagsinput,
.navbar .alert,
.card-collapse.collapse{
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.card{
    .card-body.content-full-width{
        padding: 15px 0px 10px 0px;

        .accordions{
            .card-title{
                a{
                    padding: 0px 0px 5px 15px;
                }
            }
        }
    }
    .user-image{
        width:36px;
        height:36px;
        border-radius: 12px;
        overflow:hidden;
        img{
            width:100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.rc-pagination{
    display:flex;
    li{
        border:none;
        display:flex;
        justify-content: center;
        align-items:center;
        transition: all ease-in-out 0.3s;
        &.rc-pagination-prev,
        &.rc-pagination-next{
            button{
                font-size:20px;
                line-height:20px;
                position:relative;
                &:after{
                    font-size:20px;
                    line-height:20px;
                    color:#4C2661;
                    position:absolute;
                    left:50%;
                    top:50%;
                    transform: translate(-50%,-50%);
                    -o-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    -moz-transform: translate(-50%,-50%);
                    -webkit-transform: translate(-50%,-50%);
                }
            }
        }
        &.rc-pagination-disabled{
            color:#ccc;
            border-color:#ccc;
        }
        &:hover{
            background:#4C2661;
            a{
                color:#fff;
                border-color:$color-azure;
            }
            button{
                border-color:$theme-color !important;
                background:$theme-color !important;
                &:after{
                    color:$white-color !important;
                }
                
            }
        }
        &.rc-pagination-item-active{
            background: $theme-color !important;
            color:$white-color;
            a{
                border-color:$theme-color !important;
            }
        }
        a{
            width:100%;
            height:100%;
            border:1px solid #d9d9d9;
            &:hover{
                color:#fff;
            }
        }
        a:not([href]):not([class]):hover{
            color:#fff;
        }
    }
}
.btn-info:not(:disabled):not(.disabled):active:focus{box-shadow:none;}
.filter-card{
    select{
        width:100%;
        padding:10px;
        border:1px solid $white-color;
        background:none;
        border-radius:8px;
        color:$white-color;
        option{
            padding:10px;
            color:$black-color;
        }
    }
    .form-control{
        border:1px solid #d2d6da;
        border-radius:8px;   
    }
    .btn-info{
        background:$color-azure;
        border-color:$color-azure;
        transition:all ease-in-out 0.3s;
        &:focus{
            background:$color-azure;
            border-color:$color-azure;
        }
        &:hover{
            border-color:$color-azure;
            background:#fff;
            color:$color-azure;
        }
    }
}
.filter-btns-holder{
    button{
        width:49%;
    }
}
.btn-warning{
    background:$color-purple;
    border-color:$color-purple;
    transition:all ease-in-out 0.3s;
    &:focus{
        background:$color-purple;
        border-color:$color-purple;
    }
    &:hover{
        border-color:$color-purple;
        background:#fff;
        color:$color-purple;
    }
}
.sidebar{
    .nav{
        a{
            border:none;
        }
    }
}
.textarea{
    resize: none;
}
.collection-image-holder{
    width:36px;
    height:36px;
    border-radius: 12px;
    overflow: hidden;
    img{
        width:100%;
        height:auto;
    }
}
.auction-item-details{
    font-size:14px;
    line-height:17px;
    li{
        margin:0 0 10px;
        span{
            word-break: break-all;
        }
    }
}
.Toastify__close-button{
    &:focus{
        outline:none !important;
        box-shadow:none !important;
    }
}
.alert-info{
    background-color:$theme-color !important;
    color:$white-color;
    font-size:16px;
}
*{scrollbar-width:thin;scrollbar-color:#c31977 #ccc}
::-webkit-scrollbar{width:6px;height:6px;border:none!important}
::-webkit-scrollbar-track{background:#f5cde3;border:none!important}
::-webkit-scrollbar-thumb{background-color:#c31977;border:none}
.swal2-popup{
    .swal2-title{
        font-family: "Open Sans","Helvetica Neue",Arial,sans-serif;
        font-size:22px !important;
        color:#212529 !important;
        font-weight: 400 !important;
    }
    .swal2-close{
        &:focus{
            box-shadow:none !important;
            outline:none !important;
        }
    }
    .swal2-styled{
        transition:all ease-in-out 0.3s !important;
        &:focus{
            box-shadow:none !important;
            outline:none !important;
        }
        &:hover{
            background-image:none !important;
        }
        &.swal2-confirm{
            background: #c92626 !important;
            border: 1px solid #c92626 !important;
            &:focus{
                background:$color-red !important;
                border-color:$color-red !important;
            }
            &:hover{
                border-color:$color-red !important;
                background:$white-color !important;
                color:$color-red !important;
            }
        }
        &.swal2-cancel{
            background: #888888 !important;
    border: 1px solid #888888 !important;
            &:focus{
                background:$color-dark-purple !important;
                border-color: $color-dark-purple !important;
            }
            &:hover{
               
                border: 1px solid #888888 !important;
                background:$white-color !important;
                color:#888888 !important;
            }
        }
    }
}
.countdown-wrapper{
    text-align:center;
}
.nft-detail-holder{
    li{
        margin:0 0 10px;
    }
    a{
        word-break: break-all;
    }
}
.collection-vew-block{
    .banner-holder{
        > img{
            border-radius:12px 12px 0 0;
        }
    }
    .logo-holder{
        width:80px;
        height:80px;
        border-radius:100%;
        overflow:hidden;
        position: absolute;
        left:50%;
        bottom:0;
        -o-transform: translate(-50%,50%);
        -ms-transform: translate(-50%,50%);
        -moz-transform: translate(-50%,50%);
        -webkit-transform: translate(-50%,50%);
        transform: translate(-50%,50%);
    }
}
.collection-detail{
    .nft-detail-holder{
        span{
            word-break: break-all;
        }
    }
}
.btn{
    &:not(:disabled){
        &:not(.disabled){
            &:active:focus{
                box-shadow:none;
            }
        }
    }
}
.right-label-checkbox{
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
    cursor: pointer;
    color:$white-color;
    font-size:14px;
    line-height:17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked{
            ~ .checkmark{
                border-color:$color-purple;
                background-color:$color-purple;
                &:after{
                    display:block;
                }
            }
        }
    }
    .checkmark{
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 17px;
        border:1px solid  $white-color;
        background-color:transparent;
        border-radius:4px;
        transition: all ease-in-out 0.3s;
        &:after{
            content: "";
            display: none;
            position: absolute;
            left:5px;
            top:1px;
            width: 5px;
            height: 10px;
            border: solid $white-color;
            transition: all ease-in-out 0.3s;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
.right-label-radio{
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 14px;
    line-height:17px;
    color:$white-color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked{
            ~ .checkmark{
                background-color: $color-purple;
                border-color:$color-purple;
                &:after{
                    display:block;
                }
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 17px;
        border:1px solid $white-color;
        background-color: transparent;
        border-radius: 50%;
        &:after{
            content: "";
            display: none;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $white-color;
        }
      }
}